@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600&display=swap');
* {
    margin: 0;
    padding: 0;
}

div {
    box-sizing: border-box;
}

:root {
    --wp--preset--color--black: #000000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #ffffff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--color--colibri-color-1: rgb(31, 126, 231);
    --wp--preset--color--colibri-color-2: rgb(249, 205, 2);
    --wp--preset--color--colibri-color-3: rgb(252, 122, 35);
    --wp--preset--color--colibri-color-4: rgb(254, 189, 189);
    --wp--preset--color--colibri-color-5: rgb(255, 255, 255);
    --wp--preset--color--colibri-color-6: rgb(0, 0, 0);
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
    --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
    --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
    --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
    --wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
    --wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
    --wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
    --wp--preset--duotone--dark-grayscale: url(#wp-duotone-dark-grayscale);
    --wp--preset--duotone--grayscale: url(#wp-duotone-grayscale);
    --wp--preset--duotone--purple-yellow: url(#wp-duotone-purple-yellow);
    --wp--preset--duotone--blue-red: url(#wp-duotone-blue-red);
    --wp--preset--duotone--midnight: url(#wp-duotone-midnight);
    --wp--preset--duotone--magenta-yellow: url(#wp-duotone-magenta-yellow);
    --wp--preset--duotone--purple-green: url(#wp-duotone-purple-green);
    --wp--preset--duotone--blue-orange: url(#wp-duotone-blue-orange);
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    line-height: 1.5;
    /* background-color: var(--wp--preset--color--cyan-bluish-gray); */
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.selected {
    background-color: aquamarine;
}

.hideForm {
    display: none;
}

.zimmer {
    display: flex;
    border-top: 1px solid #dcdcdc;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: space-between;
    align-items: center;
}

.zimmer:hover {
    border-top: 1px solid #1e1e1e;
}

.zimmer:first-of-type {
    border: none;
    align-items: flex-start;
    font-size: 18px;
}

.zimmer:hover+.zimmer {
    border-top: 1px solid #1e1e1e;
}

.zimmer:first-of-type:hover+.zimmer {
    border-top: 1px solid #dcdcdc;
}

.status-spacer {
    width: 20px;
    margin-left: 8px;
}

.status {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    margin-left: 8px;
}

.blocked {
    background-color: var(--wp--preset--color--luminous-vivid-amber);
}

.full {
    background-color: var(--wp--preset--color--vivid-red);
}

.free {
    background-color: var(--wp--preset--color--vivid-green-cyan);
}

.room-blocked {
    color: #dcdcdc;
    color: #b0b0b0;
}

.room-no {
    width: 140px;
    padding-left: 8px;
    /* border-right: 1px solid #1e1e1e; */
}

.room-ausstattung {
    text-align: left;
    width: 140px;
    /* border-right: 1px solid #1e1e1e; */
}

.room-zusatz::before {
    content: "\00a0";
}

.room-zusatz {
    width: 100px;
}

.zeitraum {
    display: none;
}

.zeitraum-datum {
    display: flex;
    margin-right: 30px;
}

.room-name {
    width: 260px
}

.room-email {
    width: 120px
}

.actions {
    width: 90px;
    text-align: right;
    padding-right: 8px
}

.form-email {
    margin-bottom: 16px;
}

input,
textarea {
    display: block;
    /* width: 100%; */
    /* margin-bottom: 24px; */
    height: 40px;
    margin-top: 8px;
    font-size: 16px;
    box-sizing: border-box;
    padding: 12px;
    width: 320px;
}

input[type=button],
input[type=submit],
input[type=reset],
button {
    padding: 4px 8px;
    border-radius: 100px;
    background-color: #fff;
    border: 1px solid #1e1e1e;
    cursor: pointer;
    width: auto;
    height: 36px;
    padding: 0px 10px;
}

input[type="radio"] {
    width: auto;
    height: auto;
    height: 20px;
    width: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border: 1px solid var(--primary-color);
    border-radius: 20px;
    display: inline;
    margin-right: 8px;
}

input[type="radio"]:checked {
    background-color: var(--primary-color);
}

input[type="radio"]:focus {
    outline: none;
}

input[type="radio"]:disabled {
    filter: opacity(0.1)
}

.actions button:hover {
    color: #fff;
    background-color: var(--wp--preset--color--vivid-green-cyan);
    border: 1px solid var(--wp--preset--color--vivid-green-cyan);
}

button.room-blocked:hover {
    background-color: var(--wp--preset--color--luminous-vivid-amber);
    border: 1px solid var(--wp--preset--color--luminous-vivid-amber);
}

.check {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}


/* .room-blocked>.actions button {
    ;
} */

button.room-blocked {
    border: 1px solid #b0b0b0;
}

button {
    cursor: pointer;
}


/* .zeitraum-datum>div {
    padding-right: 24px
} */

.zeitraum-single {
    width: 110px;
    font-size: 14px
}

.diemelkroon {
    display: none;
}

.diemelkroon:first-of-type {
    display: block;
    margin-top: 64px;
    ;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.content {
    max-width: 540px;
    margin: auto;
    padding: 0 32px 32px;
    background-color: #fff;
    ;
}

h2 {
    font-weight: 300;
    font-size: 2.625em;
    line-height: 1.143;
    color: rgb(51, 51, 51);
}

.content h2 {
    margin-bottom: 32px;
}

form {
    background-color: rgb(249, 205, 2);
    padding: 20px;
    border-radius: 0 0 20px 20px;
    margin: auto;
    margin-bottom: 64px;
    width: fit-content;
    position: relative;
    top: -100vh;
    transform: translateY(100vh);
    transition: all 0.6s ease-in-out;
}

form h2 {
    margin-bottom: 16px;
}

.form-label {
    font-weight: 600;
}

.fl {
    margin-top: 16px;
}

.success {
    background-color: var(--wp--preset--color--vivid-green-cyan);
    padding: 20px;
    border-radius: 0 0 20px 20px;
    margin: auto;
    margin-bottom: 64px;
    width: fit-content;
    top: -100vh;
    position: relative;
    transform: translateY(100vh);
    transition: all 0.6s ease-in-out;
}

.error {
    background-color: var(--wp--preset--color--vivid-red);
    color: #fff;
    padding: 20px;
    border-radius: 0 0 20px 20px;
    margin: auto;
    margin-bottom: 64px;
    width: fit-content;
    top: -100vh;
    position: relative;
    transform: translateY(100vh);
    transition: all 0.6s ease-in-out;
}

@media (min-width: 768px) {
    .content {
        max-width: 720px;
    }
    .zeitraum {
        display: block;
    }
}

@media (min-width: 1024px) {
    .content {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .content {
        max-width: 1232px;
    }
}